<script setup lang="ts">
let countdownInterval: number;
const newYearsEve = new Date(2025, 0, 1).getTime();
const offset = 300 * 60 * 1000;
const displayCountdown = ref(true);

const countdownValues = ref({
  days: "00",
  hours: "00",
  minutes: "00",
  seconds: "00",
});

onMounted(() => {
  countdownInterval = setInterval(() => {
    doCountdown();
  }, 500);
});

function getCountDown() {
  const timeToNewYears = new Date(newYearsEve - new Date().getTime() + offset);
  countdownValues.value.days = Math.floor(timeToNewYears.valueOf() / (1000 * 3600 * 24))
    .toString()
    .padStart(2, "0");
  countdownValues.value.hours = ("0" + timeToNewYears.getHours()).slice(-2);
  countdownValues.value.minutes = ("0" + timeToNewYears.getMinutes()).slice(-2);
  countdownValues.value.seconds = ("0" + timeToNewYears.getSeconds()).slice(-2);
}

function doCountdown() {
  const rightNow = new Date().getTime();

  if (rightNow < newYearsEve) {
    getCountDown();
  } else {
    clearInterval(countdownInterval);
    displayCountdown.value = false;
  }
}

onUnmounted(() => {
  clearInterval(countdownInterval);
});
</script>

<template>
  <div class="countdown-timer">
    <div v-if="displayCountdown" class="title"><h3>Countdown to NYE</h3></div>

    <div v-if="displayCountdown" class="timer">
      <div class="time-unit">
        <div class="value">
          {{ countdownValues.days }}
        </div>
        <div class="label">Days</div>
      </div>

      <div class="spacer">:</div>

      <div class="time-unit">
        <div class="value">
          {{ countdownValues.hours }}
        </div>
        <div class="label">Hours</div>
      </div>

      <div class="spacer">:</div>

      <div class="time-unit">
        <div class="value">
          {{ countdownValues.minutes }}
        </div>
        <div class="label">Mins</div>
      </div>

      <div class="spacer">:</div>

      <div class="time-unit">
        <div class="value">
          {{ countdownValues.seconds }}
        </div>
        <div class="label">Sec</div>
      </div>
    </div>

    <div v-if="!displayCountdown" class="happy-new-year">Happy New Year!</div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.countdown-timer {
  align-items: center;

  color: rgb(var(--primary-color, white));
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 1;

  margin: 5rem auto;

  text-transform: uppercase;

  .title {
    transition: all 0.5s;
  }

  .timer {
    color: white;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(4);
    justify-items: center;

    .time-unit {
      position: relative;
      text-align: center;
      width: 65px;

      @include queries.for-size(phone-only) {
        width: 50px;
      }

      .label {
        font-size: 1.2rem;
        font-weight: 900;
      }

      .value {
        font-size: 5rem;
        font-weight: 800;
      }
    }

    .spacer {
      align-self: top;
      font-size: 3.6rem;
    }
  }

  .happy-new-year {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
}

.drop-enter-active,
.drop-leave-active {
  transition: all 0.2s;
}
.drop-enter,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
